import Calendar from './Calendar';

class CalendarHideWeekends extends Calendar {
  constructor() {
    super();

    this.options.defaultView = 'week';
    this.options.week.taskView = false;
    this.options.week.workweek = true;
  }
}

export default CalendarHideWeekends;
