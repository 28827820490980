import Calendar from './Calendar';

class CalendarDaily extends Calendar {
  constructor() {
    super();

    this.options.defaultView = 'day';
    this.options.week.taskView = false;
  }
}

export default CalendarDaily;
