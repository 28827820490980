import { ApplicationClasses } from '@t/components';

interface Module {
  default: ApplicationClasses;
}

const makeCancelable = (
  promise: Promise<Module>
): { promise: Promise<Module>; cancel: () => void } => {
  let hasCanceled = false;

  const cancelablePromise = new Promise((resolve, reject) => {
    promise.then(
      (result) => (hasCanceled ? reject({ isCanceled: true }) : resolve(result)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: cancelablePromise as Promise<Module>,
    cancel: () => {
      hasCanceled = true;
    },
  };
};

export default makeCancelable;
