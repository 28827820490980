import Chart from './Chart';

const data = {
  categories: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  series: [
    {
      name: 'Seoul',
      data: [20, 40, 25, 50, 15, 45, 33, 34, 20, 40, 75, 50],
    },
    {
      name: 'Sydney',
      data: [5, 30, 21, 18, 59, 50, 28, 33, 21, 18, 59, 50],
    },
    {
      name: 'Moskva',
      data: [30, 5, 18, 21, 33, 21, 29, 15, 33, 21, 29, 15],
    },
  ],
};
const options = {
  chart: {
    width: 864,
    height: 480,
    title: 'Monthly Satisfaction',
  },
  yAxis: {
    title: 'Popularity',
    pointOnColumn: true,
    label: {
      margin: 14,
    },
  },
  legend: {
    align: 'bottom',
  },
  xAxis: {
    title: 'Month',
  },
  series: {
    spline: true,
  },
};

class AreaChart extends Chart {
  constructor() {
    super();

    this.data = data;
    this.options = options;
    this.factoryFunc = 'areaChart';
  }
}

export default AreaChart;
