import React from 'react';
import styled from 'styled-components';

import { ApplicationId, ProductInfo, ProductType } from '@t/components';
import { Icon } from '@/components/common/Icon';
import { InlineBlockLink } from '@/components/common/Link';
import LazyImage from '@/components/detail/LazyImage';
import { WebButtons } from '@/components/detail/ButtonSection';
import { getApplicationIconType, getRepoId } from '@/util';
import createGA from '@/components/common/createGA';

interface IntroSectionProps {
  data: ProductInfo;
}

const ComponentHeader = styled.div`
  padding: 56px 0 40px;
  background-color: #fafafa;
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 1200px) {
    padding: 56px 12.5% 40px;
  }

  @media (max-width: 720px) {
    padding: 50px 5.5%;
  }
`;

const ContentContainer = styled.div`
  max-width: 1060px;
  margin: 0 auto;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const ComponentTitle = styled.h2`
  margin: 0;
`;

const Title = styled.span`
  font-size: 38px;
  font-weight: bold;
  color: #222;
  vertical-align: middle;

  @media (max-width: 720px) {
    font-size: 32px;
  }
`;

const ComponentSlogan = styled.p`
  margin: 15px 0 30px;
  font-size: 16px;
  color: #333;

  @media (max-width: 720px) {
    font-size: 17px;
    line-height: 1.5;
    color: #222;
  }
`;

const ComponentLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1060px;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const Badges = styled.div`
  display: flex;
  height: 20px;

  & a {
    margin-right: 5px;
    position: relative;
    width: 80px;
    height: 20px;

    & img {
      height: 100%;
    }
  }
`;

const ApplicationIcon = styled(Icon)`
  width: 60px;
  height: 60px;
  margin-right: 8px;
  vertical-align: middle;

  @media (max-width: 1200px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 720px) {
    background-size: 1200px;
  }
`;

const IntroSection: React.FC<IntroSectionProps> = ({ data }) => {
  const getIconComponent = (type: ProductType, id: ApplicationId | string): React.ReactNode => {
    return type === 'component' ? null : (
      <ApplicationIcon iconType={getApplicationIconType(id as ApplicationId)} className={'icon'} />
    );
  };

  const { id, name, description, type, link, packageName, branch = 'master' } = data;
  const npmId = packageName || id;
  const repoId = getRepoId(id);
  const starButtonUrl = `https://ghbtns.com/github-btn.html?user=nhn&repo=${repoId}&type=star&count=true`;
  const ga = createGA(`Detail - ${id}`, 'click', 'Badge');

  return (
    <ComponentHeader>
      <ContentContainer>
        <ComponentTitle>
          {getIconComponent(type, id)}
          <Title>{name}</Title>
        </ComponentTitle>
        <ComponentSlogan>{description}</ComponentSlogan>
        <ComponentLinks>
          <WebButtons link={link} repoId={repoId} />
          <Badges>
            <InlineBlockLink
              type="external"
              url={`https://www.npmjs.com/package/${npmId}`}
              ga={{ ...ga, label: 'Badge - NPM' }}
            >
              <LazyImage src={`https://img.shields.io/npm/v/${npmId}.svg`} />
            </InlineBlockLink>
            <InlineBlockLink
              type="external"
              url={`https://github.com/nhn/${repoId}/blob/${branch}/LICENSE`}
              ga={{ ...ga, label: 'Badge - License' }}
            >
              <LazyImage src={`https://img.shields.io/github/license/nhn/${repoId}.svg`} />
            </InlineBlockLink>
            <iframe
              src={starButtonUrl}
              frameBorder="0"
              scrolling="0"
              width="120px"
              height="20px"
              title={id}
            />
          </Badges>
        </ComponentLinks>
      </ContentContainer>
    </ComponentHeader>
  );
};

export default IntroSection;
