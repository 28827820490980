import React from 'react';
import styled from 'styled-components';

import { InlineBlockLink } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

interface ButtonsProps {
  link: string;
  repoId: string;
}

const Wrapper = styled.div`
  & a:first-child {
    margin-right: 10px;
  }

  @media (max-width: 720px) {
    & a:first-child {
      margin: 0;
    }
  }
`;

const WebWrapper = styled(Wrapper)`
  min-width: 270px;

  @media (max-width: 720px) {
    display: none;
  }
`;

const MobileWrapper = styled(Wrapper)`
  display: none;

  @media (max-width: 720px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 17px;
    width: 89%;

    & a {
      position: relative;
      width: 48.5%;
      height: 0;
      padding-bottom: 14%;
    }
  }
`;

const Button = styled.span`
  display: inline-block;
  width: 126px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #515ce6;
  background-color: #515ce6;
  color: #fff;
  line-height: 40px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;

  &:hover,
  &:active {
    color: #515ce6;
    background-color: #fff;
  }

  @media (max-width: 720px) {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 14px;
  }
`;

const Buttons: React.FC<ButtonsProps> = ({ link, repoId }) => {
  const ga = createGA(`Detail - ${repoId}`, 'click', 'Button');

  return (
    <>
      <InlineBlockLink
        type="external"
        url={link || `https://nhn.github.io/${repoId}/latest/`}
        ga={{ ...ga, label: 'Button - API/Examples' }}
      >
        <Button>API/Examples</Button>
      </InlineBlockLink>
      <InlineBlockLink
        type="external"
        url={`https://github.com/nhn/${repoId}`}
        ga={{ ...ga, label: 'Button - Github' }}
        className="github-btn"
      >
        <Button>Github</Button>
      </InlineBlockLink>
    </>
  );
};

export const WebButtons: React.FC<ButtonsProps> = (props) => (
  <WebWrapper>
    <Buttons {...props} />
  </WebWrapper>
);

export const MobileButtons: React.FC<ButtonsProps> = (props) => (
  <MobileWrapper>
    <Buttons {...props} />
  </MobileWrapper>
);
