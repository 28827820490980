import React from 'react';

import 'tui-grid/dist/tui-grid.css';
import 'tui-date-picker/dist/tui-date-picker.css';

class Grid extends React.Component {
  componentDidMount() {
    this.createGrid();
  }

  createGrid() {}

  render() {
    return <div id="grid"></div>;
  }
}

export default Grid;
