import styled from 'styled-components';

const ContentHTML = styled.div`
  & hr {
    border: none;
    border-top: 1px solid #e5e5e5;
    margin-top: 40px;
  }

  & hr:first-child {
    border-top: none;
  }

  & p {
    margin: 20px 0 8px;
  }

  & h1,
  & h2 {
    display: none;
  }

  & h3 {
    margin: 40px 0 10px;
    font-size: 30px;
    font-weight: bold;
    color: #222;
  }

  & h4 {
    margin: 40px 0 13px;
    font-size: 24px;
    font-weight: bold;
    color: #555;
  }

  & h5 {
    margin: 20px 0 0;
    font-size: 16px;
    font-weight: bold;
    color: #555;
  }

  & ul {
    margin-bottom: 0;
    padding-left: 20px;
    line-height: 1.7;
    list-style-type: disc;
    font-size: 16px;
    color: #777;
  }

  & ul p {
    margin: 0;
  }

  & li code,
  & p code {
    font-family: Arial, sans-serif;
  }

  & code {
    font-weight: bold;
    color: #515ce6;
    background-color: rgba(81, 92, 230, 0.2);
  }

  & pre {
    display: block;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f4f4f4;
  }

  & pre code {
    font-weight: normal;
    color: #555;
    background: none;
  }

  & strong {
    font-weight: normal;
    color: #ff6618;
  }

  & table {
    width: 100%;
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px;
    border-left-style: hidden;
    border-right-style: hidden;
    font-size: 13px;
  }

  & img {
    max-width: 100%;
  }

  & table img {
    vertical-align: top;
    max-width: 100%;
  }

  & td,
  & th {
    padding: 13px;
    border: 1px solid #eee;
    color: #222;
    text-align: center;
  }

  & th {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
  }

  & a {
    text-decoration: underline;
    word-break: break-word;
  }

  @media (max-width: 720px) {
    & h3 {
      font-size: 20px;
      line-height: 1.5;
    }

    & h4 {
      font-size: 18px;
      line-height: 1.5;
    }

    & h5 {
      font-size: 16px;
      line-height: 1.5;
    }

    & li {
      font-size: 14px;
      line-height: 2;

      & p {
        font-size: 14px;
      }
    }

    & p {
      font-size: 16px;
      line-height: 1.5;
      margin: 10px 0 5px;
      word-break: break-word;
    }

    & table {
      font-size: 14px;
    }

    & img {
      max-width: 100%;
    }
  }
`;

export default ContentHTML;
