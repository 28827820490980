import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import '@toast-ui/calendar/dist/toastui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import '../../../assets/css/calendar.css';

import CalendarTemplate from './CalendarTemplate';
import { calendarList } from './DefaultCalendarList';
import { generateEvents } from './GenerateSchedules';

class CalendarMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderRange: '',
    };

    this.options = {
      defaultView: 'month',
      template: CalendarTemplate,
      useFormPopup: true,
      useDetailPopup: true,
      calendars: calendarList,
      month: {},
      week: {},
      eventFilter: (event) => {
        return (
          !(
            this.calendar.getViewName() === 'month' &&
            ['milestone', 'task'].includes(event.category)
          ) && event.isVisible
        );
      },
    };

    this.visitedRanges = [];
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.calendar = this.createCalendarMain();
    this.setEventHandlers();
    this.setSchedules();
    this.setRenderRangeText();
  }

  setSchedules() {
    const cal = this.calendar;

    const start = cal.getDateRangeStart();
    const end = cal.getDateRangeEnd();
    const currentRange = { start: start.getTime(), end: end.getTime() };

    if (this.isVisitedRange(currentRange)) {
      return;
    }

    const events = generateEvents(
      calendarList,
      cal.getViewName(),
      cal.getDateRangeStart(),
      cal.getDateRangeEnd(),
      cal.getOptions()
    );
    cal.createEvents(events);

    this.visitedRanges.push(currentRange);
  }

  createCalendarMain() {
    const TuiCalendar = this.props.application;

    return new TuiCalendar(this.containerRef.current, this.options);
  }

  setEventHandlers() {
    const cal = this.calendar;
    cal.on({
      beforeCreateEvent: (eventData) => {
        const event = {
          id: String(Date.now()),
          calendarId: eventData.calendarId,
          title: eventData.title,
          isAllDay: eventData.isAllDay,
          start: eventData.start,
          end: eventData.end,
          category: eventData.isAllDay ? 'allday' : 'time',
          dueDateClass: '',
          location: eventData.location,
          state: eventData.state,
        };

        cal.createEvents([event]);
        cal.clearGridSelections();
      },
      beforeUpdateEvent: (e) => {
        const { event, changes } = e;
        cal.updateEvent(event.id, event.calendarId, changes);
      },
      beforeDeleteEvent: (event) => {
        cal.deleteEvent(event.id, event.calendarId);
      },
    });
  }

  setRenderRangeText() {
    const cal = this.calendar;
    const options = cal.getOptions();
    const viewName = cal.getViewName();
    const html = [];
    if (viewName === 'day') {
      html.push(moment(cal.getDate().getTime()).format('YYYY.MM.DD'));
    } else if (
      viewName === 'month' &&
      (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)
    ) {
      html.push(moment(cal.getDate().getTime()).format('YYYY.MM'));
    } else {
      html.push(moment(cal.getDateRangeStart().getTime()).format('YYYY.MM.DD'));
      html.push(' ~ ');
      html.push(moment(cal.getDateRangeEnd().getTime()).format(' MM.DD'));
    }

    this.setState({
      renderRange: html.join(''),
    });
  }

  isVisitedRange(currentRange) {
    return this.visitedRanges.some(function (visitedRange) {
      return visitedRange.start === currentRange.start && visitedRange.end === currentRange.end;
    });
  }

  onClickNavi(action) {
    const cal = this.calendar;

    switch (action) {
      case 'move-prev':
        cal.prev();
        break;
      case 'move-next':
        cal.next();
        break;
      case 'move-today':
        cal.today();
        break;
      default:
        return;
    }

    this.setRenderRangeText();
    if (action !== 'move-today') {
      this.setSchedules();
    }
  }

  render() {
    return (
      <div>
        <div id="calendarMenu">
          <span id="menu-navi">
            <button
              type="button"
              className="calendar-btn calendar-move-today"
              onClick={() => this.onClickNavi('move-today')}
            >
              Today
            </button>
            <button
              type="button"
              className="calendar-btn calendar-move-day"
              onClick={() => this.onClickNavi('move-prev')}
            >
              <i className="calendar-icon ic-arrow-line-left" />
            </button>
            <button
              type="button"
              className="calendar-btn calendar-move-day"
              onClick={() => this.onClickNavi('move-next')}
            >
              <i className="calendar-icon ic-arrow-line-right" />
            </button>
          </span>
          <span className="calendar-render-range">{this.state.renderRange}</span>
        </div>
        <div id="calendar" ref={this.containerRef} style={{ height: '600px' }} />
      </div>
    );
  }
}

CalendarMain.propTypes = {
  application: PropTypes.func.isRequired,
};

export default CalendarMain;
