import ImageEditor from './ImageEditor';
import sampleImage from '../../../assets/images/imageEditor/sampleImage2.png';
import whiteTheme from './theme/whiteTheme';

class ImageEditorWhite extends ImageEditor {
  createImageEditor() {
    const TuiImageEditor = this.props.application;
    this.imageEditor = new TuiImageEditor(document.querySelector('#tui-image-editor'), {
      includeUI: {
        loadImage: {
          path: sampleImage,
          name: 'SampleImage',
        },
        theme: Object.assign({}, whiteTheme, {
          'menu.iconSize.width': '24px',
          'menu.iconSize.height': '24px',
        }),
        initMenu: 'filter',
        menuBarPosition: 'bottom',
      },
      cssMaxWidth: 650,
      cssMaxHeight: 350,
    });
  }
}

export default ImageEditorWhite;
