import Chart from './Chart';

const data = {
  categories: ['Browser'],
  series: [
    {
      name: 'Chrome',
      data: 46.02,
    },
    {
      name: 'IE',
      data: 20.47,
    },
    {
      name: 'Firefox',
      data: 17.71,
    },
    {
      name: 'Safari',
      data: 5.45,
    },
    {
      name: 'Opera',
      data: 3.1,
    },
    {
      name: 'Etc',
      data: 7.25,
    },
  ],
};
const options = {
  chart: {
    width: 864,
    height: 480,
    title: 'Browser Market Share',
  },
  series: {
    dataLabels: {
      visible: true,
      pieSeriesName: {
        visible: true,
        anchor: 'outer',
      },
    },
  },
  theme: {
    series: {
      dataLabels: {
        color: '#fff',
        fontFamily: 'sans-serif',
        fontSize: 12,
      },
    },
  },
};
class PieChart extends Chart {
  constructor() {
    super();

    this.data = data;
    this.options = options;
    this.factoryFunc = 'pieChart';
  }
}

export default PieChart;
