import Calendar from './Calendar';

class CalendarNarrowerWeekends extends Calendar {
  constructor() {
    super();

    this.options.defaultView = 'month';
    this.options.month.narrowWeekend = true;
  }
}

export default CalendarNarrowerWeekends;
