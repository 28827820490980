import React from 'react';
import styled from 'styled-components';

import { ApplicationId, ProductInfo } from '@t/components';
import { PackageName, getDemoComponent } from '@/factory/detailDemoComponentFactory';
import getDynamicDemoComponent from '@/components/demo/getDynamicDemoComponent';

interface DemoSectionProps {
  data: ProductInfo;
  selectedIndex: number;
}

interface DemoContainerProps {
  demoId: ApplicationId;
}

const demoContainerHeightMap = {
  'tui-chart': 485,
  'tui-editor': 550,
  'tui-grid': 600,
  'tui-calendar': 650,
  'tui-image-editor': 650,
};

const DemoSection = styled.div`
  border-bottom: 1px solid #e5e5e5;

  @media (max-width: 1200px) {
    padding: 0 12.5%;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const DemoContainer = styled.div<DemoContainerProps>`
  max-width: 1060px;
  margin: 0 auto;
  padding: 30px 0 70px;

  height: ${({ demoId }) => `${demoContainerHeightMap[demoId]}px`};
`;

const getContents = ({ id }: ProductInfo, selectedIndex: number): React.ReactElement | null => {
  const DemoComponent = getDynamicDemoComponent(getDemoComponent(id as PackageName)[selectedIndex]);

  return <DemoComponent id={id as PackageName} tabIndex={selectedIndex} />;
};

const DemoSectionComponent: React.FC<DemoSectionProps> = ({ data, selectedIndex }) => (
  <DemoSection>
    <DemoContainer demoId={data.id as ApplicationId}>
      {getContents(data, selectedIndex)}
    </DemoContainer>
  </DemoSection>
);

export default DemoSectionComponent;
