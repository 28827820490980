import Calendar from './Calendar';

class CalendarTimezone extends Calendar {
  componentDidMount() {
    super.componentDidMount();
    this.calendar.changeView('week');
    this.calendar.setOptions({
      week: {
        taskView: false,
      },
      timezone: {
        zones: [
          {
            timezoneName: 'Asia/Seoul',
            tooltip: 'Seoul',
          },
          {
            timezoneName: 'America/Los_Angeles',
            tooltip: 'Los Angeles',
          },
        ],
      },
      theme: {
        week: {
          dayGridLeft: {
            width: '120px',
          },
          timeGridLeft: {
            width: '120px',
          },
        },
      },
    });
  }
}

export default CalendarTimezone;
