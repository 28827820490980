import Chart from './Chart';

const gaugeData = {
  series: [
    {
      name: 'Usage Rate',
      data: [80],
    },
  ],
};

const options = {
  chart: {
    width: 864,
    height: 500,
    title: 'Usage Rate',
  },
  series: {
    solid: true,
    dataLabels: { visible: true, offsetY: -30, formatter: (value) => `${value}%` },
  },
  theme: {
    circularAxis: {
      lineWidth: 0,
      strokeStyle: 'rgba(0, 0, 0, 0)',
      tick: {
        lineWidth: 0,
        strokeStyle: 'rgba(0, 0, 0, 0)',
      },
      label: {
        color: 'rgba(0, 0, 0, 0)',
      },
    },
    series: {
      dataLabels: {
        fontSize: 40,
        fontFamily: 'Impact',
        fontWeight: 600,
        color: '#00a9ff',
        textBubble: {
          visible: false,
        },
      },
    },
  },
};

class SolidGaugeChart extends Chart {
  constructor() {
    super();

    this.data = gaugeData;
    this.options = options;
    this.factoryFunc = 'gaugeChart';
  }
}

export default SolidGaugeChart;
