import Chart from './Chart';

const data = {
  categories: ['Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov'],
  series: [
    {
      name: 'Americas',
      data: [15, 20, 18, -2, 8, 20],
    },
    {
      name: 'Asia',
      data: [25, 30, 23, 10, 20, 5],
    },
    {
      name: 'Europe',
      data: [-25, -10, -20, -7, -20, -10],
    },
    {
      name: 'Africa',
      data: [13, -25, 32, -30, -12, -15],
    },
  ],
};
const options = {
  chart: {
    width: 864,
    height: 480,
    title: 'Sales by Region Y/Y% Change',
  },
  legend: {
    align: 'bottom',
  },
  yAxis: {
    title: 'Sales(%)',
    formatter: (value) => `${value}%`,
  },
  tooltip: {
    grouped: true,
    formatter: (value) => `${value}%`,
  },
  series: {
    dataLabels: {
      visible: true,
      formatter: (value) => `${value}%`,
    },
  },
};

class ColumnChart extends Chart {
  constructor() {
    super();

    this.data = data;
    this.options = options;
    this.factoryFunc = 'columnChart';
  }
}

export default ColumnChart;
