import React from 'react';
import PropTypes from 'prop-types';
import '@toast-ui/chart';
import '@toast-ui/chart/dist/toastui-chart.css';
class Chart extends React.Component {
  componentDidMount() {
    this.createChart();
  }

  render() {
    return <div style={{ display: 'inline-block' }} id="chart" />;
  }

  createChart() {
    const container = document.getElementById('chart');
    const { data, options, factoryFunc } = this;
    const { application } = this.props;

    application[factoryFunc]({ el: container, data, options });
  }
}

Chart.propTypes = {
  application: PropTypes.func.isRequired,
};

export default Chart;
