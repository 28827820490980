import Calendar from './Calendar';

class CalendarWeekly extends Calendar {
  constructor() {
    super();

    this.options.defaultView = 'week';
    this.options.taskView = false;
  }
}

export default CalendarWeekly;
