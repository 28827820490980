import React from 'react';

import BarChart from '@/components/demo/chart/BarChart';
import ColumnChart from '@/components/demo/chart/ColumnChart';
import LineChart from '@/components/demo/chart/LIneChart';
import AreaChart from '@/components/demo/chart/AreaChart';
import PieChart from '@/components/demo/chart/PieChart';
import BubbleChart from '@/components/demo/chart/BubbleChart';
import ScatterChart from '@/components/demo/chart/ScatterChart';
import HeatmapChart from '@/components/demo/chart/HeatmapChart';
import TreemapChart from '@/components/demo/chart/TreemapChart';
import RadarChart from '@/components/demo/chart/RadarChart';
import BoxPlotChart from '@/components/demo/chart/BoxPlotChart';
import RadialBarChart from '@/components/demo/chart/RadialBarChart';
import GaugeChart from '@/components/demo/chart/GaugeChart.js';
import SolidGaugeChart from '@/components/demo/chart/SolidGaugeChart.js';

import EditorBasic from '@/components/demo/editor/EditorBasic';
import EditorAll from '@/components/demo/editor/EditorAll';

import GridPreview from '@/components/demo/grid/GridPreview';
import GridTheme from '@/components/demo/grid/GridTheme';

import CalendarMonthly from '@/components/demo/calendar/CalendarMonthly';
import CalendarWeekly from '@/components/demo/calendar/CalendarWeekly';
import CalendarDaily from '@/components/demo/calendar/CalendarDaily';
import Calendar2Weeks from '@/components/demo/calendar/Calendar2Weeks';
import Calendar3Weeks from '@/components/demo/calendar/Calendar3Weeks';
import CalendarNarrowerWeekends from '@/components/demo/calendar/CalendarNarrowerWeekends';
import CalendarHideWeekends from '@/components/demo/calendar/CalendarHideWeekends';
import CalendarTaskView from '@/components/demo/calendar/CalendarTaskView';
import CalendarOnlyTaskView from '@/components/demo/calendar/CalendarOnlyTaskView';
import CalendarTheme from '@/components/demo/calendar/CalendarTheme';
import CalendarTimezone from '@/components/demo/calendar/CalendarTimezone';

import ImageEditorBlack from '@/components/demo/image-editor/ImageEditorBlack';
import ImageEditorWhite from '@/components/demo/image-editor/ImageEditorWhite';
import ImageEditorBlackLeft from '@/components/demo/image-editor/ImageEditorBlackLeft';
import ImageEditorWhiteRight from '@/components/demo/image-editor/ImageEditorWhiteRight';
import ImageEditorBlackTop from '@/components/demo/image-editor/ImageEditorBlackTop';

export type PackageName =
  | 'tui-chart'
  | 'tui-editor'
  | 'tui-grid'
  | 'tui-calendar'
  | 'tui-image-editor';

const componentMap = {
  'tui-chart': [
    BarChart,
    ColumnChart,
    LineChart,
    AreaChart,
    PieChart,
    BubbleChart,
    ScatterChart,
    HeatmapChart,
    TreemapChart,
    RadarChart,
    BoxPlotChart,
    RadialBarChart,
    GaugeChart,
    SolidGaugeChart,
  ],
  'tui-editor': [EditorBasic, EditorAll],
  'tui-grid': [GridPreview, GridTheme, GridTheme, GridTheme],
  'tui-calendar': [
    CalendarMonthly,
    CalendarWeekly,
    CalendarDaily,
    Calendar2Weeks,
    Calendar3Weeks,
    CalendarNarrowerWeekends,
    CalendarHideWeekends,
    CalendarTaskView,
    CalendarOnlyTaskView,
    CalendarTheme,
    CalendarTimezone,
  ],
  'tui-image-editor': [
    ImageEditorBlack,
    ImageEditorWhite,
    ImageEditorBlackLeft,
    ImageEditorWhiteRight,
    ImageEditorBlackTop,
  ],
};

export function getDemoComponent(packageName: PackageName): React.ElementType[] {
  return componentMap[packageName];
}
