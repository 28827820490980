import React from 'react';
import styled from 'styled-components';

import spinner from '@/assets/images/spinner/spinner.gif';

interface SpinnerProps {
  isLoaded: boolean;
}

const Wrapper = styled.div`
  display: block;
  text-align: center;
  height: 600px;
  z-index: 100;

  &.hidden {
    display: none;
  }

  & img {
    width: 70px;
    margin-top: 150px;
  }
`;

const Spinner: React.FC<SpinnerProps> = ({ isLoaded }) => (
  <Wrapper className={`${isLoaded ? 'hidden' : ''}`}>
    <img src={spinner} alt="spinner" />
  </Wrapper>
);

export default Spinner;
