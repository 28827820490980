import Calendar from './Calendar';

class CalendarTaskView extends Calendar {
  constructor() {
    super();

    this.options.defaultView = 'week';
    this.options.week.taskView = ['task'];
  }
}

export default CalendarTaskView;
