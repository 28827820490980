import Calendar from './Calendar';

class Calendar2Weeks extends Calendar {
  constructor() {
    super();

    this.options.defaultView = 'month';
    this.options.month.visibleWeeksCount = 2;
  }
}

export default Calendar2Weeks;
