import React, { useEffect, useState } from 'react';

import { ApplicationClasses, ApplicationId } from '@t/components';
import makeCancelable from '@/components/demo/makeCancelable';
import Spinner from '@/components/Spinner';

interface DemoComponentProps {
  application?: string;
  id: ApplicationId;
  tabIndex: number;
}

interface Module {
  default: ApplicationClasses;
}

// @TODO: narrow DemoComponent type
const getDynamicDemoComponent = (
  DemoComponent: React.ElementType
): React.FC<DemoComponentProps> => {
  const DynamicDemoComponent: React.FC<DemoComponentProps> = (props) => {
    const [application, setApplication] = useState<ApplicationClasses | null>(null);

    // eslint-disable-next-line complexity
    useEffect(() => {
      const { id } = props;
      let dynamicModule: Promise<Module> | null = null;
      let cancelablePromise: {
        promise: Promise<Module>;
        cancel: () => void;
      };

      switch (id) {
        case 'tui-chart':
          dynamicModule = import('@toast-ui/chart');
          break;
        case 'tui-editor':
          dynamicModule = import('@toast-ui/editor');
          break;
        case 'tui-grid':
          dynamicModule = import('tui-grid');
          break;
        case 'tui-calendar':
          dynamicModule = import('@toast-ui/calendar');
          break;
        case 'tui-image-editor':
          dynamicModule = import('tui-image-editor');
          break;
        default:
          break;
      }

      if (dynamicModule) {
        cancelablePromise = makeCancelable(dynamicModule);
        cancelablePromise.promise
          .then((m: Module) => {
            // m['default']가 함수라 이전 state를 수정하는 함수로 인식되어 오류 발생. 따라서 함수의 반환값으로 설정하였음.
            setApplication(() => m['default']);
          })
          ['catch'](() => cancelablePromise.cancel());
      }

      return (): void => cancelablePromise.cancel();
    }, [props]);

    return application ? (
      <DemoComponent application={application} {...props} />
    ) : (
      <Spinner isLoaded={false} />
    );
  };

  return DynamicDemoComponent;
};

export default getDynamicDemoComponent;
