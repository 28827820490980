import ImageEditor from './ImageEditor';
import sampleImage from '../../../assets/images/imageEditor/sampleImage2.png';
import blackTheme from './theme/blackTheme';

class ImageEditorBlackLeft extends ImageEditor {
  createImageEditor() {
    const TuiImageEditor = this.props.application;
    this.imageEditor = new TuiImageEditor(document.querySelector('#tui-image-editor'), {
      includeUI: {
        loadImage: {
          path: sampleImage,
          name: 'SampleImage',
        },
        theme: blackTheme,
        initMenu: 'filter',
        menuBarPosition: 'left',
      },
      cssMaxWidth: 650,
      cssMaxHeight: 550,
    });
  }
}

export default ImageEditorBlackLeft;
