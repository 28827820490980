import Chart from './Chart';

const data = {
  series: [
    {
      label: 'Documents',
      children: [
        {
          label: 'docs',
          children: [
            {
              label: 'pages',
              data: 1.3,
            },
            {
              label: 'keynote',
              data: 2.5,
            },
            {
              label: 'numbers',
              data: 1.2,
            },
          ],
        },
        {
          label: 'photos',
          data: 5.5,
        },
        {
          label: 'videos',
          data: 20.7,
        },
      ],
    },
    {
      label: 'Downloads',
      children: [
        {
          label: 'recent',
          data: 5.3,
        },
        {
          label: '2020',
          data: 10.1,
        },
        {
          label: '2019',
          data: 8.2,
        },
      ],
    },
    {
      label: 'Application',
      data: 16.4,
    },
    {
      label: 'Desktop',
      data: 4.5,
    },
  ],
};

const options = {
  chart: {
    width: 864,
    height: 480,
    title: 'Used disk space',
  },
  series: {
    dataLabels: { visible: true, useTreemapLeaf: true },
    zoomable: true,
  },
  tooltip: {
    suffix: 'GB',
  },
};

class TreemapChart extends Chart {
  constructor() {
    super();

    this.data = data;
    this.options = options;
    this.factoryFunc = 'treemapChart';
  }
}

export default TreemapChart;
