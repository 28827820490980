import Chart from './Chart';

const data = {
  categories: [
    '1Q14',
    '2Q14',
    '3Q14',
    '4Q14',
    '1Q15',
    '2Q15',
    '3Q15',
    '4Q15',
    '1Q16',
    '2Q16',
    '3Q16',
    '4Q16',
    '1Q17',
    '2Q17',
    '3Q17',
    '4Q17',
  ],
  series: [
    {
      name: 'Brand A',
      data: [32.5, 33, 35, 29, 32, 25, 24.5, 20, 24.5, 21, 24, 21, 24, 20, 24, 23],
    },
    {
      name: 'Brand B',
      data: [18, 13.5, 13.5, 18, 15, 12, 12.5, 20, 17, 14.5, 14, 15.5, 15, 12, 12, 17.5],
    },
    {
      name: 'Brand C',
      data: [8, 12, 13, 15, 13, 17, 15, 16, 15, 17, 15, 20, 17, 18, 18, 20],
    },
  ],
};
const options = {
  chart: {
    width: 864,
    height: 480,
    title: 'Smartphone Sales Market Share',
    format: '1,000',
  },
  yAxis: {
    title: 'Share(%)',
    pointOnColumn: true,
    label: {
      margin: 14,
    },
  },
  legend: {
    align: 'bottom',
  },
  plot: {
    lines: [
      {
        value: '3Q14',
        color: '#fa2828',
      },
    ],
    bands: [
      {
        range: [['1Q17', '4Q17']],
        color: '#4b96e6',
        opacity: 0.15,
      },
    ],
  },
  series: {
    zoomable: true,
  },
};

class LineChart extends Chart {
  constructor() {
    super();

    this.data = data;
    this.options = options;
    this.factoryFunc = 'lineChart';
  }
}

export default LineChart;
