import Chart from './Chart';

const gaugeData = {
  series: [
    {
      name: 'Speed',
      data: [80],
    },
  ],
};

const options = {
  chart: {
    width: 864,
    height: 500,
    title: 'Speedometer',
  },
  circularAxis: {
    scale: {
      min: 0,
      max: 90,
    },
    title: 'km/h',
  },
  series: {
    angleRange: {
      start: 225,
      end: 135,
    },
  },
  plot: {
    bands: [
      { range: [0, 20], color: '#55bf3b' },
      { range: [20, 50], color: '#dddf0d' },
      { range: [50, 90], color: '#df5353' },
    ],
  },
  theme: {
    plot: { bands: { barWidth: 40 } },
  },
};

class GaugeChart extends Chart {
  constructor() {
    super();

    this.data = gaugeData;
    this.options = options;
    this.factoryFunc = 'gaugeChart';
  }
}

export default GaugeChart;
