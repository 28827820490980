import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

interface TabProps {
  name: string;
  demoTitles: string[];
  selectedIndex: number;
  onSelectTab: (index: number) => void;
}

const Wrapper = styled.div`
  @media (max-width: 1200px) {
    padding: 0 12.5%;
  }

  @media (max-width: 720px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  max-width: 1060px;
  margin: 0 auto;
`;

const TabMenus = styled.ul`
  margin: 0;
  padding: 0;
`;

const TabItem = styled.li`
  position: relative;
  display: inline-block;
  margin-right: 12px;
  padding: 40px 8px 12px;
  font-size: 14px;
  cursor: pointer;

  &:hover,
  &.selected {
    padding-bottom: 17px;
    border-bottom: 3px solid #515ce6;
  }

  &.selected button {
    font-weight: 500;
    color: #515ce6;
  }
`;

const TabButton = styled.button`
  color: #555;
`;

const Tab: React.FC<TabProps> = ({ name, demoTitles, selectedIndex, onSelectTab }) => {
  const onClickTab = (title: string, index: number): void => {
    onSelectTab(index);
    ReactGA.event({
      category: `detail - ${name}`,
      action: 'Clicked Demo tab',
      label: title,
    });
  };

  return (
    <Wrapper>
      <ContentContainer>
        <TabMenus data-testid="detail-tab">
          {demoTitles.map((title, index) => {
            const className = `${selectedIndex === index ? 'selected' : ''}`;

            return (
              <TabItem
                className={className}
                key={title}
                onClick={(): void => onClickTab(title, index)}
                data-testid={`${title}`}
              >
                <TabButton>{title}</TabButton>
              </TabItem>
            );
          })}
        </TabMenus>
      </ContentContainer>
    </Wrapper>
  );
};

export default Tab;
