import { useEffect } from 'react';
import { Editor as ToastUIEditor, EditorOptions } from '@toast-ui/editor';

export function useEditor(Editor: typeof ToastUIEditor, options: Omit<EditorOptions, 'el'>) {
  useEffect(() => {
    const el = document.getElementById('editor')!;
    // eslint-disable-next-line no-new
    new Editor({ ...options, el });
  }, [Editor, options]);
}
