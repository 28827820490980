import React from 'react';
import PropTypes from 'prop-types';
import '../../../assets/css/tui-image-editor.css';
import '../../../assets/css/image-editor.css';

class ImageEditor extends React.Component {
  componentDidMount() {
    this.createImageEditor();
  }

  render() {
    return (
      <div className="image-editor-wrap">
        <div id="tui-image-editor" />
      </div>
    );
  }
}

ImageEditor.propTypes = {
  application: PropTypes.func.isRequired,
};

export default ImageEditor;
