import React from 'react';
import PropTypes from 'prop-types';

import Grid from './Grid';

const theme = ['default', 'striped', 'clean'];

class GridTheme extends Grid {
  componentDidMount() {
    this.createGridTheme();
  }

  createGridTheme() {
    const TuiGridTheme = this.props.application;

    return new TuiGridTheme({
      el: document.getElementById('grid'),
      data: [
        {
          id: 549731,
          name: 'Beautiful Lies',
          artist: 'Birdy',
          release: '2016.03.26',
          type: 'Deluxe',
          typeCode: '1',
          genre: 'Pop',
          genreCode: '1',
          grade: '4',
          price: 10000,
          downloadCount: 1000,
          listenCount: 10050,
        },
        {
          id: 436461,
          name: 'X',
          artist: 'Ed Sheeran',
          release: '2014.06.24',
          type: 'Deluxe',
          typeCode: '1',
          genre: 'Pop',
          genreCode: '1',
          grade: '5',
          price: 20000,
          downloadCount: 1900,
          listenCount: 2005,
        },
        {
          id: 295651,
          name: 'Moves Like Jagger',
          release: '2011.08.08',
          artist: 'Maroon5',
          type: 'Single',
          typeCode: '3',
          genre: 'Pop,Rock',
          genreCode: '1,2',
          grade: '2',
          price: 7000,
          downloadCount: 11000,
          listenCount: 3100,
        },
        {
          id: 541713,
          name: 'A Head Full Of Dreams',
          artist: 'Coldplay',
          release: '2015.12.04',
          type: 'Deluxe',
          typeCode: '1',
          genre: 'Rock',
          genreCode: '2',
          grade: '3',
          price: 25000,
          downloadCount: 2230,
          listenCount: 4030,
        },
        {
          id: 265289,
          name: '21',
          artist: 'Adele',
          release: '2011.01.21',
          type: 'Deluxe',
          typeCode: '1',
          genre: 'Pop,R&B',
          genreCode: '1,3',
          grade: '5',
          price: 15000,
          downloadCount: 1007,
          listenCount: 12000,
        },
        {
          id: 555871,
          name: 'Warm On A Cold Night',
          artist: 'HONNE',
          release: '2016.07.22',
          type: 'EP',
          typeCode: '1',
          genre: 'R&B,Electronic',
          genreCode: '3,4',
          grade: '4',
          price: 11000,
          downloadCount: 1502,
          listenCount: 5000,
        },
        {
          id: 550571,
          name: 'Take Me To The Alley',
          artist: 'Gregory Porter',
          release: '2016.09.02',
          type: 'Deluxe',
          typeCode: '1',
          genre: 'Jazz',
          genreCode: '5',
          grade: '3',
          price: 30000,
          downloadCount: 1200,
          listenCount: 5003,
        },
        {
          id: 544128,
          name: 'Make Out',
          artist: 'LANY',
          release: '2015.12.11',
          type: 'EP',
          typeCode: '2',
          genre: 'Electronic',
          genreCode: '4',
          grade: '2',
          price: 12000,
          downloadCount: 8005,
          listenCount: 9000,
        },
        {
          id: 366374,
          name: 'Get Lucky',
          artist: 'Daft Punk',
          release: '2013.04.23',
          type: 'Single',
          typeCode: '3',
          genre: 'Pop,Funk',
          genreCode: '1,5',
          grade: '3',
          price: 9000,
          downloadCount: 11000,
          listenCount: 1500,
        },
        {
          id: 8012747,
          name: 'Valtari',
          artist: 'Sigur Rós',
          release: '2012.05.31',
          type: 'EP',
          typeCode: '3',
          genre: 'Rock',
          genreCode: '2',
          grade: '5',
          price: 10000,
          downloadCount: 9000,
          listenCount: 8010,
        },
        {
          id: 502792,
          name: 'Bush',
          artist: 'Snoop Dogg',
          release: '2015.05.12',
          type: 'EP',
          typeCode: '2',
          genre: 'Hiphop',
          genreCode: '5',
          grade: '5',
          price: 18000,
          downloadCount: 3000,
          listenCount: 2005,
        },
        {
          id: 491379,
          name: 'Chaos And The Calm',
          artist: 'James Bay',
          release: '2015.03.23',
          type: 'EP',
          typeCode: '2',
          genre: 'Pop,Rock',
          genreCode: '1,2',
          grade: '5',
          price: 12000,
          downloadCount: 8007,
          listenCount: 9000,
        },
      ],
      header: {
        height: 120,
        complexColumns: [
          {
            header: 'Detail Info',
            name: 'mergeColumn1',
            childNames: ['type', 'genre'],
          },
          {
            header: 'Count',
            name: 'mergeColumn2',
            childNames: ['downloadCount', 'listenCount'],
          },
          {
            header: 'Extra Info',
            name: 'mergeColumn3',
            childNames: ['mergeColumn2'],
          },
        ],
      },
      columnOptions: {
        frozenCount: 2,
      },
      columns: [
        {
          header: 'Name',
          name: 'name',
          width: 200,
        },
        {
          header: 'Artist',
          name: 'artist',
          width: 200,
        },
        {
          header: 'Type',
          name: 'type',
        },
        {
          header: 'Genre',
          name: 'genre',
        },
        {
          header: 'Download',
          name: 'downloadCount',
        },
        {
          header: 'Listen',
          name: 'listenCount',
        },
      ],
      scrollX: false,
      scrollY: false,
    });
  }

  render() {
    const TuiGridTheme = this.props.application;
    if (TuiGridTheme) {
      TuiGridTheme.applyTheme(theme[this.props.tabIndex - 1]);
    }

    return <div id="grid" />;
  }
}

GridTheme.propTypes = {
  tabIndex: PropTypes.number,
};

export default GridTheme;
