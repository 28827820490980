import Chart from './Chart';

const data = {
  categories: [
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
  ],
  series: [
    {
      name: '2015',
      data: [
        [1000, 2500, 3714, 5500, 7000],
        [1000, 2750, 4571, 5250, 8000],
        [3000, 4000, 4714, 6000, 7000],
        [1000, 2250, 3142, 4750, 6000],
        [2000, 4500, 6714, 11500, 13000],
        [3000, 5750, 7571, 8250, 9000],
        [5000, 8000, 8714, 9000, 10000],
        [7000, 9250, 10142, 11750, 12000],
        [5000, 7600, 8714, 11500, 12000],
        [8000, 9750, 10571, 12250, 14400],
        [7000, 8000, 8714, 10000, 11000],
        [1000, 2250, 3142, 4750, 6000],
        [2000, 4500, 6714, 11500, 13000],
        [3000, 5750, 7571, 8250, 9000],
        [5000, 8000, 8714, 9000, 10000],
        [7000, 9250, 10142, 11750, 12000],
      ],
      outliers: [
        [0, 14000],
        [2, 10000],
        [3, 9600],
        [10, 5000],
        [11, 8000],
        [14, 11000],
      ],
    },
  ],
};

const options = {
  chart: {
    width: 864,
    height: 480,
    title: 'Stock Prices',
  },
  yAxis: {
    title: 'Amount',
    scale: {
      min: 0,
      max: 15000,
    },
  },
  legend: {
    visible: false,
  },
};

class BoxplotChart extends Chart {
  constructor() {
    super();

    this.data = data;
    this.options = options;
    this.factoryFunc = 'boxPlotChart';
  }
}

export default BoxplotChart;
