import React from 'react';
import { EditorClass } from '@t/components';
import '@toast-ui/editor/dist/toastui-editor.css';
import { useEditor } from './hook/useEditor';

const content = [
  '![image](https://uicdn.toast.com/toastui/img/tui-editor-bi.png)',
  '',
  '# Awesome Editor!',
  '',
  'It has been _released as opensource in 2018_ and has ~~continually~~ evolved to **receive 10k GitHub ⭐️ Stars**.',
  '',
  '## Create Instance',
  '',
  'You can create an instance with the following code and use `getHtml()` and `getMarkdown()` of the [Editor](https://github.com/nhn/tui.editor).',
  '',
  '```js',
  'const editor = new Editor(options);',
  '```',
  '',
  '> See the table below for default options',
  '> > More API information can be found in the document',
  '',
  '| name | type | description |',
  '| --- | --- | --- |',
  '| el | `HTMLElement` | container element |',
  '',
  '## Features',
  '',
  '* CommonMark + GFM Specifications',
  '   * Live Preview',
  '   * Scroll Sync',
  '   * Auto Indent',
  '   * Syntax Highlight',
  '        1. Markdown',
  '        2. Preview',
  '',
  '## Support Wrappers',
  '',
  '> * Wrappers',
  '>    1. [x] React',
  '>    2. [x] Vue',
  '>    3. [ ] Ember',
].join('\n');

const options = {
  previewStyle: 'vertical' as const,
  initialEditType: 'markdown' as const,
  initialValue: content,
  height: '550px',
};

interface Props {
  application: EditorClass;
}

const EditorBasic: React.FC<Props> = ({ application }) => {
  useEditor(application, options);

  return <div id="editor" style={{ background: '#FFFFFF' }} />;
};

export default EditorBasic;
