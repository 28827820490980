import React from 'react';
import styled from 'styled-components';

import { ProductInfo } from '@t/components';

interface PreviewSectionProps {
  data: ProductInfo;
}

const Wrapper = styled.div`
  display: none;

  @media (max-width: 720px) {
    display: block;
    border-bottom: 1px solid #ddd;
    padding: 30px 5.5%;

    & div:last-child {
      margin-bottom: 30px;
    }
  }
`;

const PreviewTitle = styled.h3`
  font-size: 20px;
  margin: 0;
`;

const ImageArea = styled.div`
  width: 89%;
  height: 0;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
  padding-bottom: 50%;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const MobilePreviewSection: React.FC<PreviewSectionProps> = ({ data }) => {
  const { name, id } = data;

  return (
    <Wrapper>
      <PreviewTitle>{`${name} Preview`}</PreviewTitle>
      <ImageArea>
        <Image src={`https://uicdn.toast.com/toastui/img/${id}_mobile.png`} />
      </ImageArea>
    </Wrapper>
  );
};

export default MobilePreviewSection;
