import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { ProductInfo } from '@t/components';
import { createProductSectionData, getRepoId, replaceInvalidHtml } from '@/util';
import createGA from '@/components/common/createGA';
import SEO from '@/components/SEO';
import IntroSection from '@/components/detail/IntroSection';
import DemoSection from '@/components/detail/DemoSection';
import Tab from '@/components/detail/Tab';
import MobilePreviewSection from '@/components/detail/MobilePreviewSection';
import ContentHTML from '@/components/detail/ContentHTML';
import { MobileButtons } from '@/components/detail/ButtonSection';
import SitemapSection from '@/components/main/sitemap/SitemapSection';

import '@/query/productQueryFragment';
import '@/assets/css/tui-prismjs-theme.css';

interface DetailPageProps {
  data: {
    markdownRemark: {
      frontmatter: {
        path: string;
      };
      html: string;
    };
    productsJson: ProductInfo;
    allProductsJson: { edges: { node: ProductInfo }[] };
  };
}

const Contents = styled.div`
  margin-top: 69px;

  @media (max-width: 720px) {
    margin-top: 56px;
  }
`;

const Content = styled(ContentHTML)`
  padding: 40px 0 110px;
  line-height: 24px;
  font-size: 16px;
  color: #777;

  @media (max-width: 1200px) {
    padding: 40px 12.5% 110px;
  }

  @media (max-width: 720px) {
    width: 89%;
    margin: 0 auto;
    padding: 0;
    padding-bottom: 30px;
    font-size: 14px;
    line-height: 1.5;

    & th {
      padding: 5px 0;
      word-break: break-word;
    }
  }
`;

const ContentContainer = styled.div`
  max-width: 1060px;
  margin: 0 auto;

  @media (max-width: 720px) {
    width: 100%;
  }
`;

const DetailPage: React.FC<DetailPageProps> = ({ data }) => {
  const { markdownRemark, productsJson, allProductsJson } = data;
  const { name, description, demoTitles = [], id, link } = productsJson;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const selectTab = (index: number): void => setSelectedIndex(index);
  const getDemoTabComponent = (demoTabTitles: string[]): React.ReactNode =>
    demoTabTitles && demoTabTitles.length ? (
      <Tab
        name={productsJson.name}
        demoTitles={demoTabTitles}
        selectedIndex={selectedIndex}
        onSelectTab={selectTab}
      />
    ) : null;
  const getDemoComponent = (products: ProductInfo): React.ReactNode =>
    products.demo ? <DemoSection data={products} selectedIndex={selectedIndex} /> : null;
  const getMobilePreviewComponent = (products: ProductInfo): React.ReactNode =>
    products.demo ? <MobilePreviewSection data={productsJson} /> : null;

  const html = markdownRemark ? replaceInvalidHtml(markdownRemark.html) : '';

  const sitemapData = createProductSectionData(allProductsJson);
  const ga = createGA('FE Guide - sitemap', 'click', 'sitemap');

  return (
    <>
      <Contents>
        <SEO title={name} description={description} />
        <IntroSection data={productsJson} />
        {getDemoTabComponent(demoTitles)}
        {getDemoComponent(productsJson)}
        {getMobilePreviewComponent(productsJson)}
        <Content>
          <ContentContainer dangerouslySetInnerHTML={{ __html: html }} />
        </Content>
        <MobileButtons repoId={getRepoId(id)} link={link} />
      </Contents>
      <SitemapSection items={sitemapData} ga={ga} />
    </>
  );
};

export default DetailPage;

export const query = graphql`
  query DetailPage($id: String!) {
    # query for demo
    productsJson(id: { eq: $id }) {
      ...productJsonFragment
    }

    # query for title and article
    markdownRemark(frontmatter: { path: { eq: $id } }) {
      html
      ...markdownFragment
    }

    # query for each section
    ...allProductsJson
  }
`;
