import Calendar from './Calendar';

class CalendarTheme extends Calendar {
  componentDidMount() {
    super.componentDidMount();
    this.calendar.setTheme({
      common: {
        border: '1px solid #aaa',
        backgroundColor: '#8de0cd',
      },
      month: {
        dayName: {
          backgroundColor: '#f59a70',
        },
      },
    });
  }
}

export default CalendarTheme;
