import Chart from './Chart';

const data = {
  categories: ['June', 'July', 'Aug', 'Sep', 'Oct', 'Nov'],
  series: [
    {
      name: 'Budget',
      data: [5000, 3000, 5000, 7000, 6000, 4000],
    },
    {
      name: 'Income',
      data: [8000, 8000, 7000, 2000, 5000, 3000],
    },
    {
      name: 'Expenses',
      data: [4000, 4000, 6000, 3000, 4000, 5000],
    },
    {
      name: 'Debt',
      data: [6000, 3000, 3000, 1000, 2000, 4000],
    },
  ],
};

const options = {
  chart: {
    width: 864,
    height: 480,
    title: 'Anual Income',
  },
  series: {
    showDot: false,
    showArea: false,
  },
  plot: {
    type: 'circle',
  },
  legend: {
    align: 'top',
  },
};

class RadarChart extends Chart {
  constructor() {
    super();

    this.data = data;
    this.options = options;
    this.factoryFunc = 'radarChart';
  }
}

export default RadarChart;
