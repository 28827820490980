import Chart from './Chart';

const data = {
  categories: ['June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  series: [
    {
      name: 'Budget',
      data: [5000, 3000, 5000, 7000, 6000, 4000, 1000],
    },
    {
      name: 'Income',
      data: [8000, 1000, 7000, 2000, 6000, 3000, 5000],
    },
    {
      name: 'Expenses',
      data: [4000, 4000, 6000, 3000, 4000, 5000, 7000],
    },
    {
      name: 'Debt',
      data: [6000, 3000, 3000, 1000, 2000, 4000, 3000],
    },
  ],
};
const options = {
  chart: {
    width: 864,
    height: 480,
    title: 'Monthly Revenue',
  },
  yAxis: {
    title: 'Month',
    label: { margin: 2 },
  },
  xAxis: {
    title: 'Amount',
  },
  legend: {
    align: 'bottom',
  },
  series: {
    stack: {
      type: 'normal',
    },
  },
};

class BarChart extends Chart {
  constructor() {
    super();

    this.data = data;
    this.options = options;
    this.factoryFunc = 'barChart';
  }
}

export default BarChart;
