import Calendar from './Calendar';

class Calendar3Weeks extends Calendar {
  constructor() {
    super();

    this.options.defaultView = 'month';
    this.options.month.visibleWeeksCount = 3;
  }
}

export default Calendar3Weeks;
