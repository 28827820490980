import Chart from './Chart';

const olympicMedalData = {
  categories: ['Korea', 'United States', 'Germany', 'Canada', 'Austria'],
  series: [
    {
      name: 'Gold medals',
      data: [132, 105, 92, 73, 64],
    },
    {
      name: 'Silver medals',
      data: [125, 110, 86, 64, 81],
    },
    {
      name: 'Bronze medals',
      data: [111, 90, 60, 62, 87],
    },
  ],
};
const options = {
  chart: {
    width: 864,
    height: 480,
    title: 'Winter Olympic medals per existing country (TOP 5)',
  },
  verticalAxis: {
    label: { margin: 10 },
  },
  circularAxis: {
    label: { margin: 10 },
  },
  theme: {
    series: {
      barWidth: 20,
      colors: ['#ffd700', '#c0c0c0', '#cd7f32'],
    },
    verticalAxis: {
      label: {
        color: '#fff',
        textBubble: {
          visible: true,
          borderRadius: 5,
          backgroundColor: 'rgba(7, 59, 76, 1)',
          paddingX: 5,
          paddingY: 4,
        },
      },
    },
    circularAxis: {
      strokeStyle: 'rgba(0, 0, 0, 0.1)',
      dotColor: 'rgba(7, 59, 76, 0.8)',
      label: {
        color: '#073b4c',
      },
    },
  },
};

class RadialBarChart extends Chart {
  constructor() {
    super();

    this.data = olympicMedalData;
    this.options = options;
    this.factoryFunc = 'radialBarChart';
  }
}

export default RadialBarChart;
