/* eslint-disable require-jsdoc */
const calendarList = [];

function CalendarInfo() {
  this.id = null;
  this.name = null;
  this.checked = true;
  this.color = null;
  this.backgroundColor = null;
  this.borderColor = null;
}

function addCalendar(calendar) {
  calendarList.push(calendar);
}

function initialize() {
  let calendar;
  let id = 0;

  calendar = new CalendarInfo();
  id += 1;
  calendar.id = String(id);
  calendar.name = 'My Calendar';
  calendar.color = '#ffffff';
  calendar.backgroundColor = '#9e5fff';
  calendar.dragBackgroundColor = '#9e5fff';
  calendar.borderColor = '#9e5fff';
  addCalendar(calendar);

  calendar = new CalendarInfo();
  id += 1;
  calendar.id = String(id);
  calendar.name = 'Company';
  calendar.color = '#ffffff';
  calendar.backgroundColor = '#00a9ff';
  calendar.dragBackgroundColor = '#00a9ff';
  calendar.borderColor = '#00a9ff';
  addCalendar(calendar);

  calendar = new CalendarInfo();
  id += 1;
  calendar.id = String(id);
  calendar.name = 'Family';
  calendar.color = '#ffffff';
  calendar.backgroundColor = '#ff5583';
  calendar.dragBackgroundColor = '#ff5583';
  calendar.borderColor = '#ff5583';
  addCalendar(calendar);

  calendar = new CalendarInfo();
  id += 1;
  calendar.id = String(id);
  calendar.name = 'Friend';
  calendar.color = '#ffffff';
  calendar.backgroundColor = '#03bd9e';
  calendar.dragBackgroundColor = '#03bd9e';
  calendar.borderColor = '#03bd9e';
  addCalendar(calendar);

  calendar = new CalendarInfo();
  id += 1;
  calendar.id = String(id);
  calendar.name = 'Travel';
  calendar.color = '#ffffff';
  calendar.backgroundColor = '#bbdc00';
  calendar.dragBackgroundColor = '#bbdc00';
  calendar.borderColor = '#bbdc00';
  addCalendar(calendar);

  calendar = new CalendarInfo();
  id += 1;
  calendar.id = String(id);
  calendar.name = 'etc';
  calendar.color = '#ffffff';
  calendar.backgroundColor = '#9d9d9d';
  calendar.dragBackgroundColor = '#9d9d9d';
  calendar.borderColor = '#9d9d9d';
  addCalendar(calendar);

  calendar = new CalendarInfo();
  id += 1;
  calendar.id = String(id);
  calendar.name = 'Birthdays';
  calendar.color = '#ffffff';
  calendar.backgroundColor = '#ffbb3b';
  calendar.dragBackgroundColor = '#ffbb3b';
  calendar.borderColor = '#ffbb3b';
  addCalendar(calendar);

  calendar = new CalendarInfo();
  id += 1;
  calendar.id = String(id);
  calendar.name = 'National Holidays';
  calendar.color = '#ffffff';
  calendar.backgroundColor = '#ff4040';
  calendar.dragBackgroundColor = '#ff4040';
  calendar.borderColor = '#ff4040';
  addCalendar(calendar);
}

function findCalendar(id) {
  let found;

  calendarList.forEach(function (calendar) {
    if (calendar.id === id) {
      found = calendar;
    }
  });

  return found;
}

initialize();

export { calendarList, findCalendar };
