import ImageEditor from './ImageEditor';
import sampleImage from '../../../assets/images/imageEditor/sampleImage2.png';
import blackTheme from './theme/blackTheme';

class ImageEditorBlackTop extends ImageEditor {
  createImageEditor() {
    const TuiImageEditor = this.props.application;
    this.imageEditor = new TuiImageEditor(document.querySelector('#tui-image-editor'), {
      includeUI: {
        loadImage: {
          path: sampleImage,
          name: 'SampleImage',
        },
        theme: Object.assign({}, blackTheme, {
          'menu.iconSize.width': '24px',
          'menu.iconSize.height': '24px',
        }),
        initMenu: 'filter',
        menuBarPosition: 'top',
        uiSize: {
          width: '1124px',
          height: '100%',
        },
      },
      cssMaxWidth: 650,
      cssMaxHeight: 350,
    });
  }
}

export default ImageEditorBlackTop;
