import Calendar from './Calendar';

class CalendarMonthly extends Calendar {
  constructor() {
    super();

    this.options.defaultView = 'month';
  }
}

export default CalendarMonthly;
