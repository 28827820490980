import Calendar from './Calendar';

class CalendarOnlyTaskView extends Calendar {
  constructor() {
    super();

    this.options.defaultView = 'week';
    this.options.week.eventView = false;
  }
}

export default CalendarOnlyTaskView;
