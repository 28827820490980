import React from 'react';
import { EditorClass } from '@t/components';
import '@toast-ui/editor/dist/toastui-editor.css';

import chart from '@toast-ui/editor-plugin-chart';
import uml from '@toast-ui/editor-plugin-uml';
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// @ts-ignore
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all';

import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/chart/dist/toastui-chart.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
import { useEditor } from './hook/useEditor';

const colorSyntaxContent = [
  '## Color Syntax Plugin',
  '',
  '<span style="color:#86c1b9">Click the color picker button on the toolbar!</span>',
].join('\n');

const tableContent = [
  '## Table Merged Cell Plugin',
  '',
  '| @cols=2:merged |',
  '| --- | --- |',
  '| table | table2 |',
].join('\n');

const codeContent = [
  '## Code Syntax Highlighting Plugin',
  '',
  '```javascript',
  "console.log('foo')",
  '```',
  '```html',
  '<div id="editor"><span>baz</span></div>',
  '```',
].join('\n');

const chartContent = [
  '## Chart Plugin',
  '',
  '$$chart',
  ',category1,category2',
  'Jan,21,23',
  'Feb,31,17',
  '',
  'type: column',
  'title: Monthly Revenue',
  'x.title: Amount',
  'y.title: Month',
  'y.min: 1',
  'y.max: 40',
  'y.suffix: $',
  '$$',
  '',
].join('\n');

const umlContent = [
  '## UML Plugin',
  '',
  '$$uml',
  'partition Conductor {',
  '  (*) --> "Climbs on Platform"',
  '  --> === S1 ===',
  '  --> Bows',
  '}',
  '',
  'partition Audience #LightSkyBlue {',
  '  === S1 === --> Applauds',
  '}',
  '',
  'partition Conductor {',
  '  Bows --> === S2 ===',
  '  --> WavesArmes',
  '  Applauds --> === S2 ===',
  '}',
  '',
  'partition Orchestra #CCCCEE {',
  '  WavesArmes --> Introduction',
  '  --> "Play music"',
  '}',
  '$$',
].join('\n');

const allPluginsContent = [
  colorSyntaxContent,
  tableContent,
  codeContent,
  chartContent,
  umlContent,
].join('\n\n');

const chartOptions = {
  minWidth: 100,
  maxWidth: 600,
  minHeight: 100,
  maxHeight: 300,
};

const options = {
  previewStyle: 'vertical' as const,
  initialEditType: 'markdown' as const,
  initialValue: allPluginsContent,
  height: '550px',
  plugins: [[chart, chartOptions], codeSyntaxHighlight, colorSyntax, tableMergedCell, uml],
};

interface Props {
  application: EditorClass;
}

const EditorAll: React.FC<Props> = ({ application }) => {
  useEditor(application, options);

  return <div id="editor" style={{ background: '#FFFFFF' }} />;
};

export default EditorAll;
